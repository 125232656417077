import Vue from "vue";
import Vuetify from "vuetify/lib";
import Arrow from "@/components/icons/IconArrowDown.vue";
import Calendar from "@/components/icons/IconCalendar.vue";
import Cross from "@/components/icons/IconCross.vue";
import Minus from "@/components/icons/IconMinus.vue";
import OpenNew from "@/components/icons/IconOpenNew.vue";
import Plus from "@/components/icons/IconPlus.vue";
import Reload from "@/components/icons/IconReload.vue";
import Tooltip from "@/components/icons/IconTooltip.vue";
import Uploading from "@/components/icons/IconUploading.vue";
import Burger from "@/components/icons/IconBurger.vue";
import User from "@/components/icons/IconUser.vue";
import UserAuth from "@/components/icons/IconUserAuth.vue";
import ErrorTriangle from "@/components/icons/IconErrorTriangle.vue";
import DragArrow from "@/components/icons/IconDragArrow.vue";
import Warning from "@/components/icons/IconWarning.vue";

Vue.use(Vuetify);

const opts = {
	theme: {
		dark: true,
		options: {
			customProperties: true,
		},
		themes: {
			dark: {
				primary: `#5A49CF`,
			},
		},
	},
	breakpoint: {
		thresholds: {
			xs: 600,
			sm: 798,
			md: 1080,
			lg: 1279,
			xl: 1920,
		},
	},
	icons: {
		values: {
			arrow: {
				component: Arrow,
			},
			calendar: {
				component: Calendar,
			},
			cross: {
				component: Cross,
			},
			minus: {
				component: Minus,
			},
			openNew: {
				component: OpenNew,
			},
			plus: {
				component: Plus,
			},
			reload: {
				component: Reload,
			},
			tooltip: {
				component: Tooltip,
			},
			uploading: {
				component: Uploading,
			},
			burger: {
				component: Burger,
			},
			user: {
				component: User,
			},
			userAuth: {
				component: UserAuth,
			},
			errorTriangle: {
				component: ErrorTriangle,
			},
			dragArrow: {
				component: DragArrow,
			},
			warning: {
				component: Warning,
			},
		},
	},
};

export default new Vuetify(opts);
